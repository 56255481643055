.card-header {
    font-size: 16px !important;
    background-color: transparent !important;
    border-bottom: 1px solid lightgray !important;
}

.capitalize {
    text-transform: capitalize;
}

.gray-text {
    color: #6B6B6B
}

.width-49 {
    width: 49%;
}