.all-clients{
    .MuiTablePagination-selectLabel {
    margin-bottom : 0px !important;
}
.actions-button-cell { 
    overflow:visible; 
}

.ag-row {
    z-index: 0;
}

.ag-row.ag-row-focus {
    z-index: 1;
}
.button-section{
    display: flex;
    padding-bottom: 10px;
    height: 50px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.button-right-section{
    display: inline-flex;
}
.search-box{
    margin-right: 5px;
    height: 100%;
}
.search-icon {
    top: 4px !important;
}
.form-control{
    height: 100%;
}
.all-items-btn{
    margin-right: 10px;
    margin-bottom: 4px;

}
}