.view-estimate{
    position: relative;
    .col-style{
        height: 95%;
    }
    .estimate-table{
        border-collapse: separate;
        border-spacing: 0 15px;
    }
    .label-col{
        width: 200px;
    }
    .td-style{
        border-bottom: #e0dcdc 1px solid;
    }
    .image-container {
        flex: 0 0 auto; // Fix the width of the image container
    }
  
    .card-image {
      height: 100%; // Make the image take up the height of the card
      width: 350px;
    }

    .image-container-seal{
        display: inline-flex;
        padding-left: 35%;
        padding-top: 10%;
    }
    .card-image-seal {
        height: 100%; // Make the image take up the height of the card
        width: 200px;
      }
  
}