.all-items{
    .MuiTablePagination-selectLabel {
        margin-bottom : 0px !important;
    }
    .actions-button-cell { 
        overflow:visible; 
    }

    .ag-row {
        z-index: 0;
    }

    .ag-row.ag-row-focus {
        z-index: 1;
    }
    .button-section{
        display: block;
        padding-bottom: 10px;
        height: 50px;
    }
    .button-right-section{
        float: right;
        display: inline-flex;
        gap:20px
    }
    .all-items-btn{
        margin-right: 10px;
    }
    .search-box .form-control{
        padding-left: 10px;
    }
    .search-box .search-icon {
        font-size: 18px;
        position: absolute;
        top: 0px;
        right: 13px;
        left: auto;
        color: var(--bs-gray-600);
        line-height: 34px;
    }
}
.categoryDiv{
    min-width: 300px;
    z-index: 20;
    position: absolute;
    position: relative;
    // border:1px solid red
}