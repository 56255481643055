.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-container {
  flex: 0 0 auto;
}

.details {
  flex: 1;
  margin: 1rem;
}



.purchase-order  {
  font-size: 1rem;
}

.purchase-order-no  {
  float:right;
  font-size: 1.125rem;
  font-weight: 500;
}

.vertical-line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: #ccc;
  border-right: 1px solid #ccc;
}

.equal-height-cards {
  display: flex;
}

.equal-height-cards .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

