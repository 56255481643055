.card-content {
    display: flex;
  
    .image-container {
        flex: 0 0 auto; // Fix the width of the image container
    }
  
    .card-image {
      height: 100%; // Make the image take up the height of the card
      width: 350px;
    }
  
    .details {
      flex: 1; // Make the details container expand to fill remaining space
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      span {
        font-weight: bold; // Make details stand out
      }
    }
  }
  